import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import pickBy from 'lodash.pickby';
import cognitoConfig from '@api/auth/cognitoConfig';

export const Messages = {
  NO_USER_IN_STORAGE: 'No user found in local storage',
  SESSION_NULL: 'Session is null',
  AUTHENTICATION_FAILED: 'User is not authenticated or session is invalid',
  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully'
};

const Attributes = {
  EMAIL_VERIFIED: 'email_verified'
};

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId
});

export interface SessionData {
  accessToken: string;
  idToken: string;
  isForceChangePassword?: boolean;
}

let userForceChangePasswordAttributes: any;
let cognitoUser: CognitoUser;

export function signIn(email: string, password: string): Promise<SessionData> {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  });

  cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool
  });

  cognitoUser.setAuthenticationFlowType(process.env.REACT_APP_AUTH_TYPE || '');

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        const sessionData = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken()
        };
        resolve(sessionData);
      },
      newPasswordRequired: (userAttributes) => {
        userForceChangePasswordAttributes = userAttributes;
        resolve({
          isForceChangePassword: true,
          accessToken: '',
          idToken: ''
        });
      },
      onFailure: (err) => reject(err)
    });
  });
}

export function getCurrentSession(): Promise<SessionData> {
  const lastAuthUser = userPool.getCurrentUser();
  if (!lastAuthUser) {
    return Promise.reject(new Error(Messages.NO_USER_IN_STORAGE));
  }

  return new Promise((resolve, reject) => {
    lastAuthUser.getSession((err: any, session: any) => {
      if (err) {
        reject(err);
      } else if (!session) {
        reject(new Error(Messages.SESSION_NULL));
      } else {
        const sessionData = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken()
        };
        resolve(sessionData);
      }
    });
  });
}

export function completeNewPasswordChallenge(
  newPassword: string
): Promise<SessionData> {
  return new Promise((resolve, reject) => {
    // amazon cognito identity docs say we should not send the field email_verified
    const userAttributes = pickBy(
      userForceChangePasswordAttributes,
      (_, key) => key !== Attributes.EMAIL_VERIFIED
    );
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: (session) => {
        const sessionData = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken()
        };
        resolve(sessionData);
      },
      onFailure: (err) => reject(err)
    });
  });
}

export async function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<string> {
  const lastAuthUser = userPool.getCurrentUser();
  if (!lastAuthUser) {
    return Promise.reject(new Error(Messages.NO_USER_IN_STORAGE));
  }
  await new Promise((resolve, reject) => {
    lastAuthUser.getSession((err: any, session: any) => {
      if (err || !session) {
        reject(new Error(Messages.AUTHENTICATION_FAILED));
      } else {
        resolve(session);
      }
    });
  });

  return new Promise((resolve, reject) => {
    lastAuthUser.changePassword(oldPassword, newPassword, (err) => {
      if (err) {
        reject(err.message || JSON.stringify(err));
      } else {
        resolve(Messages.PASSWORD_CHANGE_SUCCESS);
      }
    });
  });
}

export function signOut(): void {
  const lastAuthUser = userPool.getCurrentUser();
  if (!lastAuthUser) {
    console.error(Messages.NO_USER_IN_STORAGE);
    return;
  }
  lastAuthUser.signOut();
}

export function forgotPassword(email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  });
}

export function confirmForgotPassword(
  code: string,
  newPassword: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      }
    });
  });
}
